import { render, staticRenderFns } from "./safeWorkPermitPop.vue?vue&type=template&id=5e23ffc6&"
import script from "./safeWorkPermitPop.vue?vue&type=script&lang=js&"
export * from "./safeWorkPermitPop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\PSM-PROJECT\\CMMS\\andamiCMMSFrontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e23ffc6')) {
      api.createRecord('5e23ffc6', component.options)
    } else {
      api.reload('5e23ffc6', component.options)
    }
    module.hot.accept("./safeWorkPermitPop.vue?vue&type=template&id=5e23ffc6&", function () {
      api.rerender('5e23ffc6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/sop/swp/safeWorkPermitPop.vue"
export default component.exports